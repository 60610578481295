import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import map from 'lodash/map';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import Link from '../../components/Link';

const TagsPage = ({ data, pageContext, location }) => {
  const { allMarkdownRemark, site } = data;
  const { group } = allMarkdownRemark;
  const { title } = site.siteMetadata;
  const { t } = useTranslation();

  return (
    <Layout lang={pageContext.lang} indexed={false} location={location}>
      <section className="section">
        <Helmet title={`${t('tags.tags')} | ${title}`} />
        <div className="container content">
          <div className="columns">
            <div
              className="column is-10 is-offset-1"
              style={{ marginBottom: '6rem' }}
            >
              <h1 className="title is-size-2 is-bold-light">Tags</h1>
              <ul className="taglist">
                {map(group, tag => (
                  <li key={tag.fieldValue}>
                    <Link to={`/tags/${kebabCase(tag.fieldValue)}`}>
                      {tag.fieldValue} ({tag.totalCount})
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({ fieldValue: PropTypes.string }),
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }),
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
